/*
 * Header styles
 */

.user-icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: white;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    color: #fb5856;
}

.navbar-default {
    background-color: #fb5856;
}
