a {
    color: #fb5856;
    &:hover{
        color:#fb5856;
    }
}

.navbar-right > li > a {
    color: #ffffff !important;
    line-height: 60px;
    padding: 0px 15px;
    position: relative;
}


.wrapper-page {
    margin: 5% auto;
    position: relative;
    width: 420px;
}


.logo-login {
    display: block;
    max-width: 100%;
    height: auto;
}
